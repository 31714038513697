import GenericModel from "@/shared/models/generic-model";
import i18n from "@/shared/plugins/vue-i18n";
import StringField from "@/shared/fields/string-field";

const label = (name) =>
    i18n.t(`APPLICATION_CIRCULAR_MAIL.${name.toUpperCase()}`);

const fields = {
    subject: new StringField("subject", label("subject"), { required: true }),
    message: new StringField("message", label("message"), { required: true }),
};

export class ApplicationCircularMailModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
