<template>
    <st-page :title="titleComputed" :backLink="backLink" layout="tab" pageCustomClass="application-view">
        <Loader v-if="isLoading" />
        <b-card class="st-section" id="application-details-target">
            <div class="st-section-body">
                <form class="form" novalidate="novalidate" id="st_circular_mail_form">
                    <div class="d-flex">
                        <div class="col-12 pl-0 d-flex">
                            <b-form-checkbox size="lg" v-model="model.entire_organisation" :value="true"
                                :unchecked-value="false">
                                {{ $t('APPLICATION_CIRCULAR_MAIL.SEND_TO_ALL_ORGANIZATIONS') }}
                            </b-form-checkbox>
                        </div>
                    </div>
                    <div class="d-flex mt-5">
                        <div class="col-12 pl-0 d-flex">
                            <div class="col p-0 form-group">
                                <label>
                                    {{ $t('APPLICATION_CIRCULAR_MAIL.DEPARTMENT') }}
                                </label>
                                <st-search v-model="model.selectedDepartments" :options="departmentsOptions"
                                    ref="selectedDepartments" name="selectedDepartments" :multiple="true"
                                    :searchFnc="searchForDepartments"
                                    :searchText="$t('APPLICATION_CIRCULAR_MAIL.SEARCH_FOR_DEPARTMENTS')"
                                    :placeholder="$t('APPLICATION_CIRCULAR_MAIL.SEARCH_PLACEHOLDER_DEPARTMENTS')"
                                    @selected="processSelectedDepartment"
                                    :disabled="model.entire_organisation"></st-search>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mt-5">
                        <div class="col-12 pl-0 d-flex">
                            <div class="col p-0 form-group">
                                <label>
                                    {{ $t('APPLICATION_CIRCULAR_MAIL.USERS') }}
                                </label>
                                <st-search v-model="model.selectedUsers" :options="usersOptions" ref="selectedUsers"
                                    name="selectedUsers" :multiple="true" :searchFnc="searchForUsers"
                                    :searchText="$t('APPLICATION_CIRCULAR_MAIL.SEARCH_FOR_USERS')"
                                    :placeholder="$t('APPLICATION_CIRCULAR_MAIL.SEARCH_PLACEHOLDER_USERS')"
                                    @selected="processSelectedUser" :disabled="model.entire_organisation"></st-search>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mt-5">
                        <div class="col-12 pl-0 d-flex">
                            <div class="col p-0 form-group">
                                <label>
                                    {{ $t('APPLICATION_CIRCULAR_MAIL.ADDITIONAL_RECIPIENT') }}
                                </label>
                                <st-input-text v-model="model.non_user_emails" ref="non_user_emails"
                                    name="non_user_emails"
                                    :placeholder="$t('APPLICATION_CIRCULAR_MAIL.ADDITIONAL_RECIPIENT_PLACEHOLDER')" />
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mt-5">
                        <div class="col-12 pl-0 d-flex">
                            <div class="col p-0 form-group">
                                <label>
                                    {{ $t('APPLICATION_CIRCULAR_MAIL.SUBJECT') }} *
                                </label>
                                <st-input-text v-model="model.subject" ref="subject" name="subject" />
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mt-5">
                        <div class="col-12 pl-0 d-flex">
                            <div class="col p-0 form-group">
                                <label>
                                    {{ $t('APPLICATION_CIRCULAR_MAIL.MESSAGE') }} *
                                </label>
                                <b-form-textarea v-model="model.message" id="textarea-message" ref="message"
                                    name="message" rows="4"></b-form-textarea>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mt-5">
                        <div class="col p-0 form-group">
                            <label>
                                {{ `${$t("APPLICATION_CIRCULAR_MAIL.ATTACHMENTS")}` }}
                            </label>
                            <div>
                                <div :class="['drop-zone', dragging ? 'drop-zone-over' : '', fileMissingError ? 'drop-zone-error' : '']"
                                    @dragenter="dragging = true" @dragleave="dragging = false">
                                    <div class="drop-zone-info" @drag="onChange">
                                        <div class="drop-zone-info-texts">
                                            <span class="drop-zone-title">
                                                {{ $t("APPLICATION_CIRCULAR_MAIL.FILE_UPLOAD_INFO_MESSAGE") }}
                                            </span>
                                            <div class="drop-zone-upload-limit-info">
                                                <div>{{ $t("APPLICATION_CIRCULAR_MAIL.MAX_FILE_SIZE") }}</div>
                                            </div>
                                            <div v-if="fileUploadError" class="red">
                                                {{ $t("APPLICATION_CIRCULAR_MAIL.FILE_UPLOAD_ERROR") }}
                                            </div>
                                            <div v-if="fileMissingError" class="red">
                                                {{ $t("APPLICATION_CIRCULAR_MAIL.FILE_MISSING_ERROR") }}
                                            </div>
                                        </div>
                                    </div>
                                    <input type="file" accept="application/pdf, image/png, image/jpeg, image/jpg"
                                        @change="onChange">
                                </div>
                            </div>
                            <div v-if="files.length" class="file-names">
                                <div class="text-center" v-for="(file, fileKey) in files" v-bind:key="fileKey">
                                    {{ file.name }}
                                    <i class="fa fa-trash remove-file-icon font-size-sm ml-2"
                                        @click="removeFile(fileKey)"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center mt-6">
                        <st-button type="button" class="btn btn-primary mr-4" :callback="handleSend">
                            {{ $t("GENERAL.BUTTON.SEND") }}
                        </st-button>
                        <st-button type="button" class="btn btn-outline-primary" :callback="handleCancel">
                            {{ $t("GENERAL.BUTTON.CANCEL") }}
                        </st-button>
                    </div>
                </form>
            </div>
        </b-card>
    </st-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ApiService from '@/shared/services/api.service';
import { FormSchema } from '@/shared/form/form-schema';
import { ApplicationCircularMailModel } from '@/modules/applications/models/application-circular-mail.model';
import { createFormValidation } from "@/shared/utils/create-form-validation";

const { fields } = ApplicationCircularMailModel;
const formSchema = new FormSchema([
    fields.subject,
    fields.message,
])

export default {
    name: 'ApplicationCircularMail',
    data() {
        return {
            model: {
                non_user_emails: '',
                subject: '',
                message: '',
                selectedUsers: [],
                selectedDepartments: [],
                selectedOrganizations: [],
                entire_organisation: false,
            },
            departmentsOptions: [],
            usersOptions: [],
            organizationsOptions: [],
            files: [],
            fileUploadError: false,
            fileMissingError: false,
            dragging: false,
            users: [],
            departments: [],
            rules: formSchema.rules(),
            fv: null,
            fields,
        };
    },
    computed: {
        ...mapGetters({
            accountDetails: "auth/accountDetails",
            uploadAccessToken: "notifications/form/uploadAccessToken",
        }),
        titleComputed() {
            return this.$t("APPLICATION_CIRCULAR_MAIL.TITLE");
        },
        backLink() {
            return { name: "applications" };
        },
        isLoading() {
            return false;
        },
        organizationId() {
            return this.accountDetails.organisation_id;
        }
    },
    methods: {
        ...mapActions({
            getCircularToken: 'notifications/form/getCircularToken',
            doSendCircular: 'notifications/form/doSendCircular',
            doUpload: "applications/form/upload",
        }),
        async searchForUsers(inputValue) {
            if (inputValue.length > 3) {
                const payload = {
                    query: `organisation_id eq ${this.organizationId} and last_name like \'${inputValue}\'`
                }

                const filters = { params: { ...payload, pagination: true } };
                const { data } = await ApiService.query('users', filters);
                const users = data?.data || [];
                if (!users?.length) {
                    return [];
                }
                return users?.map((row) => ({
                    value: row?.id,
                    text: `${row?.first_name} ${row?.last_name}`,
                    user: row,
                }));
            }
            return [];
        },
        async searchForDepartments(inputValue) {
            if (inputValue.length > 3) {
                const payload = { query: `organisation_id eq ${this.organizationId} and name contains \'${inputValue}\'` };

                const filters = { params: { ...payload, pagination: true } };

                const { data } = await ApiService.query('/departments', filters);
                const departments = data?.data || [];

                if (!departments?.length) {
                    return [];
                }
                return departments?.map((row) => ({
                    value: row?.id,
                    text: row?.name,
                    department: row,
                }));
            }
            return [];
        },

        async handleSend() {
            try {
                const isValid = await this.fv.validate();
                if (isValid !== 'Valid') {
                    return;
                }
                if (!this.model.entire_organisation && !this.departments.length && !this.users.length && !this.model.non_user_emails) {
                    this.$alert({
                        type: 'warning',
                        title: this.$t('APPLICATION_CIRCULAR_MAIL.ERROR_TITLE'),
                        text: this.$t('APPLICATION_CIRCULAR_MAIL.ERROR_TEXT'),
                    });
                    return;
                }
                let attachments = [];
                if (this.files.length) {
                    const uploadedFiles = await this.handleUploadFile();
                    attachments = uploadedFiles?.map((file) => file.id) || [];
                }
                const nonUserEmails = this.model.non_user_emails.split(',');

                const departmentIds = this.departments?.map((department) => department.id) || [];
                const userIds = this.users?.map((user) => user.user_id) || [];
                const data = {
                    subject: this.model.subject,
                    message: this.model.message,
                    user_ids: userIds,
                    department_ids: departmentIds,
                    organisation_id: this.organizationId,
                    non_user_emails: nonUserEmails,
                    entire_organisation: this.model.entire_organisation,
                    attachments,
                };
                const response = await this.doSendCircular(data);
                if (response) {
                    const title = this.$t('APPLICATION_CIRCULAR_MAIL.SUCCESS_TITLE');
                    const text = this.$t('APPLICATION_CIRCULAR_MAIL.SUCCESS_TEXT');
                    this.$alert({
                        type: 'success',
                        title,
                        text: text,
                        isTextHtml: true,
                        showConfirmButton: false,
                        callback: () => {
                            this.$router.push({ name: 'applications' });
                        },
                    });
                }
            } catch (e) {
                console.error(e);
            }
        },
        handleCancel() {
            this.$router.push({ name: 'applications' });
        },
        onChange(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.dragging = false;
                return;
            }
            this.fileUploadError = false;
            this.fileMissingError = false;
            for (const file of files) {
                this.createFile(file);
            }
        },
        createFile(file) {
            if (file.size > 52428800) {
                this.fileUploadError = true;
                this.dragging = false;
                return;
            }

            this.files.push(file);
        },
        removeFile(index) {
            this.files.splice(index, 1);
        },
        async handleUploadFile() {
            try {
                const formData = new FormData();
                for (let i = 0; i < this.files?.length; i++) {
                    const file = this.files[i];
                    if (file instanceof File) {
                        formData.append('files', file);
                    }
                }

                return await this.doUpload({
                    files: formData,
                    uploadAccessToken: this.uploadAccessToken
                });
            } catch (e) {
                throw e;
            }
        },
        processSelectedDepartment(data) {
            if (data.selected) {
                this.departments = data.selected.map((el) => el.department);
                this.departmentsOptions = [];
            } else {
                this.departments = [];
            }
        },
        processSelectedUser(data) {
            if (data.selected) {
                this.users = data.selected.map((el) => el.user);
                this.usersOptions = [];
            } else {
                this.users = [];
            }
        },
    },
    async mounted() {
        await this.getCircularToken();
        this.fv = createFormValidation('st_circular_mail_form', this.rules);
    },
};
</script>